<template>
    <b-card :title="`${$t('statuses')}`">
        <form @submit.prevent="createStatus" @keydown="form.onKeydown($event)" class="edit-form" enctype="multipart/form-data">
            <b-row>
                <b-col sm="6">
                    <div class="form-group">
                        <label for="name">{{ $t('name') }}<span class="text-danger">*</span></label>
                        <input type="text" name="name" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name" />
                    </div>
                    <div class="form-group">
                        <label for="type">{{ $t('type') }}<span class="text-danger">*</span></label>
                        <select v-model="form.type" class="form-control">
                            <option value="order">{{ $t('order') }}</option>
                            <option value="payment">{{ $t('payment') }}</option>
                            <option value="delivery">{{ $t('delivery') }}</option>
                        </select>
                        <has-error :form="form" field="type" />
                    </div>
                    <div class="form-group">
                        <label for="type">{{ $t('default_selected') }}<span class="text-danger">*</span></label>
                        <select v-model="form.selected" class="form-control">
                            <option :value="true">{{ $t('selected') }}</option>
                            <option :value="false">{{ $t('deselected') }}</option>
                        </select>
                        <has-error :form="form" field="type" />
                    </div>
                </b-col>
                <b-col sm="6">
                    <h5>{{ $t('show_for') }}:</h5>
                    <div v-for="item in deliveries" class="custom-control custom-checkbox" :key="item.id">
                        <input type="checkbox" class="custom-control-input" :id="`checkbox-${item.id}`" v-model="form.show_for" :value="item.id">
                        <label class="custom-control-label" :for="`checkbox-${item.id}`">{{ item.name }}</label>
                    </div>
                    <has-error :form="form" field="show_for" />
                    <hr>
                    <h5>{{ $t('show_for_statuses') }}:</h5>
                    <div v-for="payment in payments" class="custom-control custom-checkbox" :key="payment.id+11">
                        <input type="checkbox" class="custom-control-input" :id="`checkbox-p-${payment.id}`" v-model="form.show_for_payments" :value="payment.id">
                        <label class="custom-control-label" :for="`checkbox-p-${payment.id}`">{{ payment.name }}</label>
                    </div>
                    <has-error :form="form" field="show_for_payments" />
                </b-col>
            </b-row>
            <button type="submit" class="btn btn-success" :disabled="form.busy">{{ $t('add') }}</button>
        </form>
    </b-card>
</template>
<script>
import Form from 'vform'
import axios from 'axios'
export default {
    middleware: 'auth',
    layout: 'default',
    metaInfo () {
        return { title: this.$t('statuses') }
    },
    data: () => ({
        form: new Form({
            name: '',
            type: '',
            selected: false,
            show_for: [],
            show_for_payments: []
        }),
        deliveries: null,
        payments: null
    }),
    mounted () {
        this.fetchDeliveries()
        this.fetchPayments()
    },
    methods: {
        async createStatus () {
            this.form.busy = true
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            await this.form.post(apiUrl + 'statuses').then(resp => {
                this.$router.push({ name: 'statuses' })
            }).catch(error => {
                this.form.errors.set(error.response.data.error.errors)
            })
        },
        async fetchDeliveries () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'deliveries')
            this.deliveries = data.data
        },
        async fetchPayments () {
            const apiUrl = process.env.VUE_APP_ENDPOINT_V1
            const { data } = await axios.get(apiUrl + 'payments')
            this.payments = data.data
        }
    }
}
</script>
